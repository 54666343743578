import React, { Fragment } from "react"
import './App.css';
import Main from "./components/Main/Main";
// import Nav from "./components/Nav/Nav";
// import Wrapper from "./components/Wrapper/Warpper"

function App() {
  return (
    <Fragment>
      {/* <Nav /> */}
      <Main />
    </Fragment>
  );
}

export default App;
